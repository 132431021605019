import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
import { IDiagramVariable } from "../../application_workflow/utilities/interfaces";
import { IProtocol } from "../../application_data/components/protocols/utilities/interfaces";

/**Is used in combination with the chips component to populate the chip display.
 * @remark This service is **not provided** in root and should be added to the component providers
 * of the component using it.
 */
@Injectable()
export class ChipService {
  private chips: BehaviorSubject<Array<IProtocol | IDiagramVariable>>;
  private individualChip: Subject<IProtocol | IDiagramVariable>;
  constructor() {
    this.chips = new BehaviorSubject<Array<IProtocol | IDiagramVariable>>([]);
    this.individualChip = new Subject<IProtocol | IDiagramVariable>();
  }

  get allChips() {
    return this.chips.asObservable();
  }

  get newChip() {
    return this.individualChip.asObservable();
  }

  setChips(data: Array<IProtocol | IDiagramVariable>) {
    this.chips.next(data);
  }
  addChip(data: IProtocol | IDiagramVariable) {
    this.individualChip.next(data);
  }
}
