import { IGenericIconConfig, ISymbolConfig } from "./interfaces";

/**Represents a JSON object. It has a key:value pair structure, value being unknown by default. */
export type TJsonObject<ObjectType = unknown> = Record<string, ObjectType>;
/**An array of JSON object. */
export type TJsonObjects = Array<TJsonObject>;
/**Encompasses both JSON object types into a unified JSON entity.*/
export type TJsonEntity = TJsonObject | TJsonObjects;

export type TSplitterType = "space" | "dash" | "dot";
export type TPlatformTheme = "dark" | "light";
export type TInteractionTheme =
  | "warn"
  | "primary"
  | "accent"
  | "light"
  | "dark"
  | "brand";

export type TIconConfig = ISymbolConfig | IGenericIconConfig;
/**Defines the icon for the defined snackbar type */
export enum snackIcon {
  success = "done",
  failure = "error_outline",
  info = "info",
}

/**Used to make marked properties of a type required and leave other properties as they were. */
export type WithRequired<T, K extends keyof T> = T & { [P in K]-?: T[P] };
