<div style="position: relative; margin-top: 60px">
  <div class="flex-row flex-wrap justify-space-between align-center">
    <div class="flex-row align-center">
      <div
        class="flex-column justify-center"
        style="padding-bottom: 0"
        [ngClass]="{ 'show-counter': counter() }"
        [attr.data-count]="counter()"
      >
        <h1 style="margin-bottom: 8px">{{ title() }}</h1>
        @if (subtitle()) {
        <h3 [style]="{ 'margin-bottom': '8px', color: '#8f9199' }">
          {{ subtitle() }}
        </h3>
        }
      </div>
    </div>
    <div class="flex-row gap-8 actions align-center">
      @if (includeSearchBar()) {
      <generic-search-bar
        ng-displayBind
        [title]="'Search in ' + title().toLowerCase()"
        (filterTermChange)="onSearch($event)"
      />
      } @if (includeDialogHandler()) {
      <button
        mat-flat-button
        data-wizard-target="table-button-interaction"
        class="button-adjustments"
        (click)="handleAddClick()"
        color="accent"
        data-cy="generic-table-create"
      >
        <span>{{ buttonText() }} {{ title().toLowerCase() }}</span>
        <generic-icon
          style="margin-left: 4px"
          [iconConfiguration]="{
              renderEntity: 'add',
            }"
        />
      </button>
      }
    </div>
  </div>
</div>
