<div
  class="empty-or-error-box empty-state"
  [ngClass]="{
    'empty-state': !data(),
    'error-state': !data() && error,
    eradicate: data() && !error
  }"
>
  <span class="text">{{ fallbackText }}</span>
</div>
