import {
  Directive,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  booleanAttribute,
  input,
} from "@angular/core";
import { Subject, fromEvent, takeUntil } from "rxjs";

@Directive({
  selector: "[expansionMenu]",
})
export class ExpansionMenuDirective implements OnInit, OnDestroy {
  /**Defines if the collapse behaviour is animated or not. */
  expansionType = input<"simple" | "fancy">("simple");
  /**Should the toggle icon be added or not. */
  @Input({ transform: booleanAttribute }) addToggle = false;
  /**If true the expansion menu will be rendered open by default. */
  @Input({ transform: booleanAttribute })
  openMenu = false;

  currentState: "flex" | "none" = "none";

  expansionHeader: HTMLElement | undefined;
  expansionBody: HTMLElement | undefined;
  expansion: HTMLElement | undefined;

  directiveIsDestroyed: Subject<boolean> = new Subject();

  constructor(
    private renderer: Renderer2,
    private el: ElementRef<HTMLElement>
  ) {
    this.expansion = this.el.nativeElement;
    this.renderer.setStyle(this.expansion, "display", "flex");
    this.renderer.setStyle(this.expansion, "flex-direction", "column");
    this.renderer.setStyle(this.expansion, "user-select", "none");
  }
  ngOnDestroy(): void {
    this.directiveIsDestroyed.next(true);
    this.directiveIsDestroyed.complete();
  }
  ngOnInit(): void {
    if (!this.expansion) return;
    this.currentState = this.openMenu ? "flex" : "none";
    this.expansionHeader = this.expansion.children[0] as HTMLElement;
    if (this.expansionHeader) {
      this.expansionBody = this.renderer.nextSibling(this.expansionHeader);
      this.setupHeader(this.expansionHeader);
    }
    if (this.expansionBody) {
      this.setupDropdown();
    }
  }

  private onClick(): void {
    if (!this.expansionBody) return;
    const nextState = this.opositeState;
    if (this.expansionType() === "fancy") {
      const expansionWrap = this.renderer.parentNode(this.expansionBody);
      this.renderer.setStyle(
        expansionWrap,
        "grid-template-rows",
        nextState === "flex" ? "1fr" : "0fr"
      );
    } else {
      this.renderer.setStyle(this.expansionBody, "display", nextState);
    }
    if (this.addToggle) {
      const toggleIcon = this.expansionHeader?.querySelector(".chevronToggle");
      this.renderer.setStyle(
        toggleIcon,
        "transform",
        nextState === "flex" ? "rotate(180deg)" : "rotate(0deg)"
      );
    }
    this.currentState = nextState;
  }

  private setupDropdown(): void {
    if (this.expansionType() === "simple") {
      this.renderer.setStyle(this.expansionBody, "display", this.currentState);
      return;
    }
    this.renderer.removeChild(this.expansion, this.expansionBody);
    const expansionWrap = this.renderer.createElement("div");
    this.renderer.setStyle(expansionWrap, "display", "grid");
    this.renderer.setStyle(
      expansionWrap,
      "grid-template-rows",
      this.openMenu ? "1fr" : "0fr"
    );
    this.renderer.setStyle(
      expansionWrap,
      "transition",
      "grid-template-rows 200ms ease-in-out"
    );
    this.renderer.setStyle(this.expansionBody, "overflow", "hidden");
    this.renderer.appendChild(this.expansion, expansionWrap);
    this.renderer.appendChild(expansionWrap, this.expansionBody);
  }

  private setupHeader(expansionHeader: HTMLElement): void {
    this.renderer.setStyle(expansionHeader, "cursor", "pointer");
    fromEvent<MouseEvent>(expansionHeader, "click")
      .pipe(takeUntil(this.directiveIsDestroyed))
      .subscribe(() => this.onClick());

    if (!this.addToggle) return;
    const toggleIcon = this.renderer.createElement("mat-icon");
    this.renderer.addClass(toggleIcon, "mat-icon");
    this.renderer.addClass(toggleIcon, "material-icons-outlined");
    this.renderer.addClass(toggleIcon, "material-icons");
    this.renderer.addClass(toggleIcon, "chevronToggle");
    this.renderer.appendChild(
      toggleIcon,
      this.renderer.createText("expand_more")
    );
    if (this.expansionType() === "fancy") {
      this.renderer.setStyle(
        toggleIcon,
        "transition",
        "transform 200ms ease-in-out"
      );
    }
    this.renderer.appendChild(expansionHeader, toggleIcon);
    this.renderer.setStyle(
      toggleIcon,
      "transform",
      this.currentState === "flex" ? "rotate(180deg)" : "rotate(0deg)"
    );
  }

  private get opositeState(): "flex" | "none" {
    return this.currentState === "flex" ? "none" : "flex";
  }
}
