<div
  class="wizard-dialog flex-column gap-8"
  [style.max-width]="currentStep ? currentStep.width : '150px'"
  [style]="{
    'max-width': currentStep ? currentStep.width : '150px',
    padding: currentStep ? 0 : '0.5rem'
  }"
>
  @if (!currentStep) {
  <generic-context-loader
    ng-displayBind
    loadingText="Loading step"
    [gapSize]="4"
    theme="light"
  />
  } @else {
  <div class="flex-row gap-4 wizard-dialog-controls">
    @if (currentStep.backButton) {
    <button
      class="density-high"
      matTooltip="Previous"
      (click)="processButton(currentStep.backButton)"
      mat-flat-button
    >
      <generic-icon
        [iconConfiguration]="{
          renderType: 'symbol',
          renderEntity: 'arrow_back'
        }"
      />
    </button>
    }
    <button
      [style.margin-left]="'auto'"
      matTooltip="Help"
      class="density-high"
      (click)="openHelpWindow()"
      mat-flat-button
    >
      <generic-icon [iconConfiguration]="{ renderEntity: 'help_outline' }" />
    </button>
    <button
      matTooltip="Close"
      class="density-high"
      (click)="closeWizard()"
      mat-flat-button
    >
      <generic-icon [iconConfiguration]="{ renderEntity: 'close' }" />
    </button>
  </div>
  <div class="wizard-dialog-wrap flex-column">
    <div class="wizard-dialog-title">
      <span>{{ currentStep.title }}</span>
    </div>
    <div class="wizard-dialog-content">
      @for (paragraph of currentStep.content; track paragraph) {
      <p [innerHTML]="paragraph"></p>
      }
    </div>
    @if (currentStep.buttonChoices; as buttons) {
    <div class="wizard-dialog-actions">
      @for (button of buttons; track button.text) {
      <button
        color="light"
        mat-button
        class="density-low"
        (click)="processButton(button)"
        [ngClass]="{
          'mdc-button--outlined mat-mdc-outlined-button':
            button.type === 'outlined',
          'mat-mdc-unelevated-button': button.type === 'flat'
        }"
      >
        {{ button.text }}
      </button>
      }
    </div>
    }
  </div>
  }
</div>
