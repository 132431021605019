import { Component, HostBinding, input, Input, OnInit } from "@angular/core";
import { IGenericCardConfig } from "../../utilities/interfaces";

@Component({
  selector: "generic-card",
  templateUrl: "./generic-card.component.html",
  styleUrls: ["./generic-card.component.scss"],
})
export class GenericCardComponent implements OnInit {
  configuration = input.required<IGenericCardConfig>({ alias: "config" });
  @HostBinding("class.fancy") fancyDisplay = false;
  ngOnInit(): void {
    this.fancyDisplay = !!this.configuration().isFancy;
  }
}
