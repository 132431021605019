@let setIconConfig = iconConfiguration(); @if (setIconConfig.renderType ===
'symbol') {
<span
  [ngClass]="[
    'material-symbols-' + (setIconConfig.symbolType ?? 'outlined'),
    'size-' + (setIconConfig.symbolOptSize ?? 'md'),
    'symbol-' + (setIconConfig.symbolState ?? 'empty'),
    'gradiation-' + (setIconConfig.symbolGrade ?? 'mid'),
    'weight-' + (setIconConfig.symbolWeight ?? '400')
  ]"
  [ngStyle]="{
    'font-size': setIconConfig.renderSize ?? '24px'
  }"
>
  {{ setIconConfig.renderEntity }}
</span>
} @else if (setIconConfig.renderType === 'material' ||
!setIconConfig.renderType) {
<mat-icon
  fontSet="material-icons-outlined"
  [ngStyle]="{
    width: setIconConfig.renderSize ?? '24px',
    height: setIconConfig.renderSize ?? '24px',
    'font-size': setIconConfig.renderSize ?? '24px'
  }"
>
  {{ setIconConfig.renderEntity }}
</mat-icon>
} @else if (setIconConfig.renderType === 'added') {
<mat-icon
  [svgIcon]="setIconConfig.renderEntity"
  [ngStyle]="{
    width: setIconConfig.renderSize ?? '24px',
    height: setIconConfig.renderSize ?? '24px',
    'font-size': setIconConfig.renderSize ?? '24px'
  }"
/>
} @else if (setIconConfig.renderType === 'custom') {
<img
  [src]="setIconConfig.renderEntity | safeUrl"
  [ngStyle]="{
    width: setIconConfig.renderSize ?? '24px',
    height: setIconConfig.renderSize ?? '24px',
    'font-size': setIconConfig.renderSize ?? '24px'
  }"
  alt="custom icon"
/>
}
