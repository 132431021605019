import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { MatChipInputEvent } from "@angular/material/chips";
import { ENTER, SPACE } from "@angular/cdk/keycodes";
import { MatDialogRef } from "@angular/material/dialog";
import { WizardService } from "src/app/modules/shared/component/wizard-overlay/services/wizard.service";
import { IDialogActions } from "../../utilities/interfaces";
import { IRevisionMessage } from "src/app/modules/application_data/components/conversions/components/conversion-handling/utility/interfaces";

@Component({
  selector: "save-content-dialog",
  templateUrl: "./save-content-dialog.component.html",
  styleUrls: ["./save-content-dialog.component.scss"],
})
export class SaveContentDialogComponent
  implements OnInit, AfterViewInit, IDialogActions
{
  @ViewChild("tagInput", { static: true })
  draftTag!: ElementRef;

  saveContentControl!: UntypedFormGroup;
  separatorActivations = [ENTER, SPACE];
  constructor(
    private dialogRef: MatDialogRef<
      SaveContentDialogComponent,
      IRevisionMessage
    >,
    private _wizardService: WizardService
  ) {}

  ngAfterViewInit(): void {
    this._wizardService.componentLoad();
  }
  ngOnInit(): void {
    this.buildForm();
  }

  buildForm() {
    this.saveContentControl = new UntypedFormGroup({
      tags: new UntypedFormControl([]),
      messages: new UntypedFormControl(),
    });
  }

  addTagFromInput(event: MatChipInputEvent) {
    if (event.value) {
      const values = this.saveContentControl.controls["tags"]
        .value as Array<string>;
      values.push(event.value);
      event.chipInput.clear();
    }
  }
  removeTag(tagIndex: number) {
    const values = this.saveContentControl.controls["tags"]
      .value as Array<string>;
    values.splice(tagIndex, 1);
  }

  onSubmit(): void {
    if (this.saveContentControl.invalid) return;
    const revisionMessage: IRevisionMessage = {
      messages: [
        this.saveContentControl.controls["messages"].value === null
          ? ""
          : this.saveContentControl.controls["messages"].value,
      ],
      tags: this.saveContentControl.controls["tags"].value,
    };
    this._wizardService.outsideInteraction();
    this.dialogRef.close(revisionMessage);
  }
  onClose(): void {
    this._wizardService.outsideInteraction();
    this.dialogRef.close();
  }
}
