import {
  AfterViewInit,
  Component,
  EventEmitter,
  input,
  Input,
  OnInit,
  output,
  Output,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { IGenericTableConfig } from "../../utilities/interfaces";
import { WizardService } from "../wizard-overlay/services/wizard.service";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { sanitizeSearchTerm } from "../../utilities/utils";

@Component({
  selector: "generic-table-component",
  templateUrl: "./generic-table.component.html",
  styleUrls: ["./generic-table.component.scss"],
})
export class GenericTableComponent implements OnInit, AfterViewInit {
  tableActions = input<TemplateRef<Element>>();
  tableSpecialColumn = input<TemplateRef<Element>>();
  tableLoading = input<boolean>();
  tableConfiguration = input<IGenericTableConfig<unknown>>({
    title: "",
    columns: [],
    includeCounter: true,
    includeCreateButton: true,
    includeSearchBar: true,
    tableData: [],
  });
  createEvent = output();

  dataSource = new MatTableDataSource();
  tableColumns!: Array<string>;
  @ViewChild(MatPaginator, { static: true })
  paginator!: MatPaginator;
  constructor(private _wizardService: WizardService) {}
  ngAfterViewInit(): void {
    this._wizardService.componentLoad();
  }

  ngOnInit(): void {
    this.configureTable();
  }
  filterData(value: string) {
    this.dataSource.filter = sanitizeSearchTerm(value);
  }

  paginatiorInteraction(interaction: PageEvent) {
    this.setPageSize(interaction.pageSize);
  }
  private configureTable() {
    const currentTableConfig = this.tableConfiguration();
    if (currentTableConfig.tableData.length > 0) {
      this.dataSource.data = currentTableConfig.tableData;
      this._wizardService.setFallbackState(false);
    } else {
      this.dataSource = new MatTableDataSource();
      this._wizardService.setFallbackState(true);
    }
    this.tableColumns = currentTableConfig.columns.map<string>(
      (column) => column.name
    );
    this.tableColumns.push("actions");
    this.readPageSize();
    this.dataSource.paginator = this.paginator;
  }

  private readPageSize() {
    const cookies = document.cookie.split(";");
    const foundPageSize = cookies.findIndex((cookie) =>
      cookie.includes(`horizon_paginator_size`)
    );
    if (foundPageSize === -1) return;
    const foundPageValue = this.unpackPageSize(cookies[foundPageSize]);
    if (!foundPageValue) return;
    if (this.paginator.pageSizeOptions.includes(foundPageValue)) {
      this.paginator._changePageSize(foundPageValue);
      return;
    }
    this.removePageSize();
  }
  private unpackPageSize(foundCookie: string): number | undefined {
    return +foundCookie.split("=")[1];
  }
  private setPageSize(pageSize: number) {
    document.cookie = `horizon_paginator_size=${pageSize};path=/;max-age=2592000;Secure;SameSite=Strict`;
  }
  private removePageSize() {
    document.cookie = `horizon_paginator_size=${0};path=/;max-age=0;Secure;SameSite=Strict`;
  }
}
