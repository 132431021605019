import { Component, HostBinding, input, Input } from "@angular/core";
import { TIconConfig } from "../../utilities/types";

@Component({
  selector: "generic-icon",
  templateUrl: "./generic-icon.component.html",
  styleUrls: ["./generic-icon.component.scss"],
})
export class GenericIconComponent {
  /**Icon configuration data.*/
  iconConfiguration = input.required<TIconConfig>();
  genericState = input<"flex" | "inline-flex">("inline-flex");
  @HostBinding("style.display") display = this.genericState();
}
