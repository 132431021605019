import { Injectable } from "@angular/core";
import { IAppApplication } from "../../application/utilities/interfaces";
import { TUserRole } from "../../application/utilities/types";

@Injectable({
  providedIn: "root",
})
export class GlobalStorageService {
  private selectedApplication!: IAppApplication;
  private preview: boolean;
  constructor() {
    this.preview = false;
  }

  public setApplication(application: IAppApplication): void {
    this.selectedApplication = application;
  }

  get application(): IAppApplication {
    return this.selectedApplication;
  }

  get applicationRole(): TUserRole {
    return this.preview ? "VIEWER" : this.selectedApplication.role;
  }

  get applicationId(): string {
    return this.selectedApplication.id;
  }

  previewModeOn(): void {
    this.preview = true;
  }

  previewModeOff(): void {
    this.preview = false;
  }
}
