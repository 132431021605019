import {
  Directive,
  ElementRef,
  HostListener,
  input,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { Subject, take, takeUntil, timer } from "rxjs";
import { GlobalStorageService } from "../services/globalstorage.service";
import { Clipboard } from "@angular/cdk/clipboard";
@Directive({
  selector: "[displayFormat]",
})
export class DisplayFormatDirective implements OnInit, OnDestroy {
  controllingForm = input.required<FormControl>();
  formatPattern = input.required<string>();

  private componentIsDestroyed: Subject<boolean>;
  constructor(
    private renderer: Renderer2,
    private el: ElementRef,
    private _globalStorageService: GlobalStorageService,
    private clipboard: Clipboard
  ) {
    this.componentIsDestroyed = new Subject();
  }
  ngOnDestroy(): void {
    this.componentIsDestroyed.next(true);
    this.componentIsDestroyed.complete();
  }
  ngOnInit(): void {
    const formattedDisplay = this.formatPattern().replace(
      "{{applicationId}}",
      this._globalStorageService.applicationId
    );
    this.controllingForm()
      .valueChanges.pipe(takeUntil(this.componentIsDestroyed))
      .subscribe((newValue) => {
        this.renderer.setProperty(
          this.el.nativeElement,
          "textContent",
          formattedDisplay.replace("{{value}}", newValue)
        );
      });
    this.renderer.setProperty(
      this.el.nativeElement,
      "textContent",
      formattedDisplay.replace("{{value}}", this.controllingForm().value)
    );
  }

  @HostListener("click") copyContent(): void {
    this.clipboard.copy(this.el.nativeElement.childNodes[0].data);
    this.attachInfo();
  }

  private attachInfo(): void {
    if (this.el.nativeElement.children.length !== 0) {
      return;
    }
    const copyIcon = this.renderer.createElement("mat-icon");
    this.renderer.addClass(copyIcon, "mat-icon");
    this.renderer.addClass(copyIcon, "material-icons-outlined");
    this.renderer.addClass(copyIcon, "material-icons");
    this.renderer.addClass(copyIcon, "form-template-hint");
    this.renderer.appendChild(copyIcon, this.renderer.createText("thumb_up"));
    this.renderer.appendChild(this.el.nativeElement, copyIcon);
    timer(1200)
      .pipe(take(1))
      .subscribe(() =>
        this.renderer.removeChild(this.el.nativeElement, copyIcon)
      );
  }
}
