<h1 mat-dialog-title class="center">
  <span>{{ data.title }}</span>
</h1>
<h4 class="dialog-subtitle center">{{ data.description }}</h4>
<mat-dialog-actions class="flex-row action-alignment">
  <button
    mat-flat-button
    color="{{ this.data.isNotification ? this.data.dialogTheme : 'primary' }}"
    data-cy="decline-action"
    (click)="onClose()"
  >
    {{ this.data.isNotification ? "Ok" : "No" }}
  </button>
  @if (!this.data.isNotification) {
  <button
    mat-stroked-button
    data-cy="confirm-action"
    [color]="this.data.dialogTheme"
    (click)="onSubmit()"
  >
    Yes
  </button>
  }
</mat-dialog-actions>
