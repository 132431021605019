import { Component, input, Input } from "@angular/core";

@Component({
  selector: "generic-context-loader",
  templateUrl: "./generic-context-loader.component.html",
  styleUrls: ["./generic-context-loader.component.scss"],
})
export class GenericContextLoaderComponent {
  /**The text that will displayed above the loading bar. */
  loaderText = input<string>("Loading", { alias: "loadingText" });
  /**Set the loader theme to light or default. */
  theme = input<"default" | "light" | "dark">("default");
  /**The gap size between the loading text and the bar. */
  gapSize = input(8);
  /**Size of the loading text font. Just the basic number input. */
  fontSize = input(22);
  /**If set to absolute, the loader will be of position absolute and take all available space of the relative parent. */
  positioning = input<"absolute" | "regular">("regular");
  /**If set to darkened, the loader will have a background color. Usually used in combination with the absolute positioning. */
  background = input<"darkened" | "transparent">("transparent");

  constructor() {}
}
