import { AfterViewInit, Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { IConfirmDialogData, IDialogActions } from "../../utilities/interfaces";
import { WizardService } from "../wizard-overlay/services/wizard.service";



@Component({
  selector: "generic-confirm-dialog",
  templateUrl: "./generic-confirm-dialog.component.html",
  styleUrls: ["./generic-confirm-dialog.component.scss"],
})
export class GenericConfirmDialogComponent
  implements IDialogActions, AfterViewInit
{
  constructor(
    public dialogRef: MatDialogRef<GenericConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IConfirmDialogData,
    private _wizardService: WizardService
  ) {}
  ngAfterViewInit(): void {
    this._wizardService.componentLoad();
  }
  onSubmit(): void {
    this.dialogRef.close(true);
  }
  onClose(): void {
    this.dialogRef.close(false);
  }
}
