import {
  Component,
  EventEmitter,
  input,
  Input,
  output,
  Output,
} from "@angular/core";
import { WizardService } from "src/app/modules/shared/component/wizard-overlay/services/wizard.service";

@Component({
  selector: "app-table-card-header",
  templateUrl: "./table-card-header.component.html",
  styleUrls: ["./table-card-header.component.scss"],
})
export class TableCardHeaderComponent {
  title = input<string>("");
  subtitle = input<string>("");
  includeDialogHandler = input<boolean>(false);
  includeSearchBar = input<boolean>(false);
  counter = input<number>();
  buttonText = input<string>("Create");

  createEvent = output();
  searchTermChange = output<string>();

  constructor(private _wizardService: WizardService) {}

  handleAddClick(): void {
    this.createEvent.emit();
    this._wizardService.outsideInteraction();
  }
  onSearch(text: string): void {
    this.searchTermChange.emit(text);
  }
}
